import * as React from "react"
import styled from 'styled-components'
import { Helmet } from 'react-helmet'
import { graphql, Link } from 'gatsby'
import { BlockRenderer, Layout, Portrait } from '@components'
import { H1 } from '../components/BlockRenderer'
import { palette, typeface } from '@theme'


interface PageProps {
  data: {
    sanityPage: GatsbyTypes.SanityPage
  }
}


const AboutPage = ({ data }: PageProps) => {
  const { sanityPage: { _rawDescription, _rawBiography, collaborators }} = data
  return (
    <>
      <Helmet>
        <meta name="theme-color" content={palette.cosmos} />
      </Helmet>
      <Layout background={palette.cosmos} >
        <Header>
          <Portrait />
          <Introduction>
            <BlockRenderer>{_rawDescription}</BlockRenderer>
          </Introduction>
        </Header>
        <Biography>
          <BlockRenderer>{_rawBiography}</BlockRenderer>
        </Biography>
        <Collaborators>
          <H1>Collaborators</H1>
          <ul>
            {collaborators?.filter(collaborator => !!collaborator).map(collaborator => !!collaborator && (
              <li key={collaborator!._key}>
                {collaborator.link && (
                  <Link to={collaborator.link} target="_blank" >{collaborator.name}</Link>
                )}
                {!collaborator.link && (
                  <span>{collaborator.name}</span>
                )}
              </li>
            ))}
          </ul>
        </Collaborators>
      </Layout>
    </>
  )
}



const Header = styled.header`
  display: flex;
  flex-direction: column-reverse;
  background-color: ${palette.cosmos};
  color: ${palette.balticSea};
  @media only screen and (min-width: 744px) {
    flex-direction: row;
    align-items: center;
  }
`


const Introduction = styled.div`
  box-sizing: border-box;
  font-size: 32px;
  line-height: 1em;

  padding: 120px 0 40px 0;
  @media only screen and (max-width: 743px) {
    height: 80.5vh;
  }
  @media only screen and (min-width: 744px) {
    padding: 14vw 0 4vw 0;
  }
  @media only screen and (min-width: 1366px) {
    padding: 12vw 0 4vw 0;
  }
  @media only screen and (min-width: 2000px) {
    padding: 240px 0 80px 0;
  }

  margin: 0 15px;
  @media only screen and (min-width: 744px) {
    margin: 1.5em auto;
    > * {
      max-width: calc(100% - 6vw);
      padding-right: 20px;
    }
  }
  a {
    font-family: ${typeface.sans}, sans;
    font-weight: 900;
    text-decoration: none;
    &:hover {
      color: ${palette.lime};
    }
  }
`


const Section = styled.section`
  box-sizing: border-box;
  > * {
    max-width: calc(100vw - 30px);
    margin: auto;
    padding: 8vw 0;
  }
  @media only screen and (min-width: 744px) {
    > * {
      max-width: 90%;
      padding: 4vw 0;
    }
  }
  @media only screen and (min-width: 2000px) {
    > * {
      max-width: 1840px;
      padding: 4vw 0;
    }
  }
`


const Biography = styled(props => <Section {...props} />)`
  background-color: ${palette.balticSea};
  color: ${palette.white};
  > * {
    max-width: calc(100vw - 30px);
    margin: auto;
    padding: 16vw 0;
  }
  @media only screen and (min-width: 744px) {
    > * {
      max-width: 60%;
      padding: 4vw 0;
    }
  }
  
  h3 {
    color: ${palette.rawUmber};
  }
  
  a {
    font-family: ${typeface.sans};
    font-weight: 900;
    color: ${palette.cosmos};
    &:hover {
      color: ${palette.lime};
    }
  }
`


const Collaborators = styled(props => <Section {...props} />)`
  background-color: ${palette.rawUmber};
  text-transform: uppercase;
  box-sizing: border-box;

  @media only screen and (max-width: 743px) {
    padding: 8vw 0;
  }
  
  h1 {
    color: white;
    margin-bottom: 0;
  }
  ul {
    padding-top: 0;
    column-count: 2;
    column-gap: 30px;
    font-family: ${typeface.sans};
    line-height: 1.1em;
    font-weight: 900;
    @media only screen and (hover: none) {
      line-height: 1.8em;
    }
    @media only screen and (min-width: 744px) {
      column-count: 4;
      font-size: 2.3vw;
    }
    @media only screen and (min-width: 2000px) {
      font-size: 46px;
    }
  }
  color: ${palette.cosmos};
  a {
    text-decoration: none;
    transition: color 0.15s ease-in-out;
    color: ${palette.cosmos};
    &:hover {
      color: ${palette.lime};
    }
  }
`


export const query = graphql`
  query AboutDeltaPageQuery {
    sanityPage(slug: {current: {eq: "about"}}) {
      ...pageFields
    }
  }
`


export default AboutPage
